.home__hero {
  height: 60vh;
  min-height: 400px;
  padding: 0 90px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.home__hero,
.home__hero h2 {
  color: #fff;
}
.home__hero h2 {
  font-size: 60px;
  font-family: Raleway;
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .home__hero {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.home__content {
  padding: 0 0;
  height: 100%;
  max-width: 1425px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .home__content h2 {
    font-size: 40px;
    line-height: 100%;
  }
}
.home__buttons {
  padding-top: 25px;
  letter-spacing: 0.5px;
  -webkit-font-smoothing: antialiased;
  z-index: 140px;
  position: relative;
}
.home__button {
  background: #ea9c1f;
  display: inline-block;
  color: #fff;
  border-radius: 2px;
  padding: 13px 17px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 12px;
  transition: border-color 0.2s linear, background-color 0.2s linear;
}
.home__button:hover {
  background: #db921d;
}
.home__pagecontent {
  width: 100%;
  display: flex;
}
.home__intro {
  flex: 1 1 auto;
  padding-right: 100px;
}
.home__sidebar {
  flex: 0 0 300px;
}
.home__sponsors {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
}
.home__sponsor {
  width: 33%;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .home__sponsor {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .home__sponsor {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .home__pagecontent {
    display: block;
  }
  .home__intro {
    padding-right: 0;
    margin-bottom: 35px;
  }
}