.newsitems__categories {
  margin: 20px 0;
}

.newsitems__category {
  display: inline-block;
  text-decoration: none;
  border: 1px solid #000;
  padding: 10px 15px;
  margin-right: 10px;
  margin-bottom: 15px;
  transition: background-color 0.3s, color 0.3s;
}
.newsitems__category--selected, .newsitems__category:hover {
  background-color: #0d3579;
  color: #fff;
}

.news__items {
  list-style-type: none;
  margin: 25px 0 0;
  padding: 0;
}
.news__item-li {
  margin-bottom: 40px;
}
.news__item {
  margin: 10px 0 25px;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
}
.news__image-container {
  width: 25%;
}
.news__image {
  display: block;
  max-width: 100%;
  height: auto;
  transition: transform 0.3s;
}
.news__image-link {
  overflow: hidden;
  display: block;
}
.news__image-link:hover .news__image {
  transform: scale(1.1);
}
.news__info {
  width: 75%;
  padding-left: 25px;
}
.news__date {
  margin-bottom: 10px;
}
.news__heading {
  margin-top: 0;
}
.news__readmore-container {
  text-align: right;
  margin-top: 10px;
}
.news--small .news__summary,
.news--small .news__readmore-container {
  display: none;
}
.news--small .news__item,
.news--small .news__item-li {
  margin-bottom: 0;
}
.news--small .news__image-container {
  width: 20%;
}
.news--small .news__info {
  width: 80%;
  padding-left: 20px;
}
@media screen and (max-width: 768px) {
  .news__image-container, .news--small .news__image-container {
    width: 100%;
  }
  .news__info, .news--small .news__info {
    width: 100%;
    padding-left: 0;
  }
}

.newsdetails__image {
  margin: 0 0 15px;
}
.newsdetails__image img {
  max-height: 200px;
  width: auto;
}
.newsdetails__back {
  margin-bottom: 10px;
}
.newsdetails__date {
  margin-bottom: 20px;
}
.newsdetails__addthis {
  margin: 0 0 25px;
}

.emb-news-ctas {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  flex: 1;
}
.emb-news-ctas__col {
  width: calc(33.3333333333% - 40px);
  display: flex;
  flex-direction: column;
  background-clip: content-box;
  margin-bottom: 30px;
}
.emb-news-ctas__col--image {
  max-width: 100%;
  height: 310px;
  object-fit: cover;
}
.emb-news-ctas__col--text h2 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}
.emb-news-ctas__col--text p {
  overflow: hidden;
  line-height: 25px;
  margin-bottom: 25px;
}
.emb-news-ctas__col--button {
  align-self: flex-start;
  margin-top: auto;
}
@media screen and (max-width: 1024px) {
  .emb-news-ctas__col {
    width: calc(50% - 40px);
  }
  .emb-news-ctas__col .button {
    max-width: unset;
  }
}
@media screen and (max-width: 767px) {
  .emb-news-ctas__col {
    width: 100%;
    margin: 40px 0 0 0;
  }
  .emb-news-ctas__col--text p {
    height: auto;
  }
}

@supports (display: grid) {
  html.use-grid-css .emb-news-ctas {
    display: grid;
    grid-gap: 35px 25px;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
  html.use-grid-css .emb-news-ctas__col {
    width: auto;
  }
  @media screen and (max-width: 1024px) {
    html.use-grid-css .emb-news-ctas {
      grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    }
  }
  @media screen and (max-width: 767) {
    html.use-grid-css .emb-news-ctas {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
  }
}
@supports not (display: grid) {
  html.use-grid-css .emb-news-ctas {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    flex: 1;
  }
}
html.use-grid-css .emb-news-ctas__col {
  display: flex;
  flex-direction: column;
  background-clip: content-box;
  margin-bottom: 30px;
}
@supports not (display: grid) {
  html.use-grid-css .emb-news-ctas__col {
    width: calc(33.3333333333% - 40px);
  }
}
html.use-grid-css .emb-news-ctas__col--image {
  max-width: 100%;
  height: 310px;
  object-fit: cover;
}
html.use-grid-css .emb-news-ctas__col--text h2 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}
html.use-grid-css .emb-news-ctas__col--text p {
  overflow: hidden;
  line-height: 25px;
  margin-bottom: 25px;
}
html.use-grid-css .emb-news-ctas__col--button {
  align-self: flex-start;
  margin-top: auto;
}
@supports not (display: grid) {
  @media screen and (max-width: 1024px) {
    html.use-grid-css .emb-news-ctas__col {
      width: calc(50% - 40px);
    }
    html.use-grid-css .emb-news-ctas__col .button {
      max-width: unset;
    }
  }
  @media screen and (max-width: 767px) {
    html.use-grid-css .emb-news-ctas__col {
      width: 100%;
      margin: 40px 0 0 0;
    }
    html.use-grid-css .emb-news-ctas__col--text p {
      height: auto;
    }
  }
}

.news__heading a {
  font-size: inherit;
}
.news__item-li {
  list-style: none !important;
}
.news__item {
  display: flex;
  flex-direction: row-reverse;
}
.news__image {
  margin: 115px 0 0 15px;
}
.news ul.news__items {
  margin-left: 0;
}
.news__info {
  width: 75%;
  padding-left: 0;
}
@media screen and (max-width: 768px) {
  .news__info {
    width: 100%;
  }
}

.newsdetails__image {
  display: none;
}