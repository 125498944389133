.team__members {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  align-items: stretch;
}
.team__member {
  width: 33.3333%;
  display: flex;
  flex-direction: column;
}
.team__member-image-container, .team__member-name, .team__member-info {
  text-align: left;
}
.team__member-name {
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 5px;
}
.team__member-name--header {
  font-size: 1.5em;
}
.team__member-image {
  width: 150px;
  height: 150px;
}
.team__member-info {
  margin-top: 0;
}
.team .readmore-link {
  align-self: flex-start;
  margin-top: auto;
}
@media screen and (max-width: 768px) {
  .team__member {
    width: 100%;
  }
}

.teamdetails {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
}
.teamdetails__image-container {
  width: 20%;
}
.teamdetails__image {
  display: block;
  max-width: 100%;
  height: auto;
}
.teamdetails__info {
  width: calc(80% - 50px);
  padding-left: 50px;
}
@media screen and (max-width: 768px) {
  .teamdetails__image-container {
    width: 100%;
    padding-bottom: 25px;
  }
  .teamdetails__info {
    width: 100%;
    padding-left: 0;
  }
}

.team {
  float: none;
}
.team__rtd {
  margin-bottom: 40px;
}
.team__member {
  margin-bottom: 40px;
}
.team__member-link {
  text-decoration: none;
  font-size: 1.2em;
  font-weight: 700;
  color: #000;
}