.agenda__items {
  margin: 25px 0 0;
  padding: 0;
}
.agenda__item {
  margin: 10px 0 25px;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
}
.agenda__image-container {
  width: 25%;
}
.agenda__image {
  display: block;
  max-width: 100%;
  height: auto;
}
.agenda__info {
  width: calc(75% - 25px);
  padding-left: 25px;
}
.agenda__date {
  margin-bottom: 10px;
}
.agenda__heading {
  margin-top: 0;
}
.agenda__readmore-container {
  text-align: right;
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  .agenda__image-container {
    width: 100%;
  }
  .agenda__info {
    width: 100%;
    padding-left: 0;
  }
}

.agendadetails__back {
  margin-bottom: 10px;
}
.agendadetails__date {
  margin-bottom: 20px;
}

.agenda__heading a {
  font-size: inherit;
}
.agenda .agenda__items .agenda__item .agenda__image-container {
  display: none;
}
.agenda .agenda__items .agenda__item .agenda__info {
  width: 100%;
}
.agenda__readmore-link {
  display: none;
}

.agendadetails__date {
  color: orange;
}