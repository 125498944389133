.content {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.content__col--rtd {
  flex: 1 1 auto;
  padding-right: 40px;
}
.content__col--widgets {
  flex: 0 0 150px;
}
.content--nowidgets .content__col--rtd {
  padding-right: 0;
}
.content--nowidgets .content__col--widgets {
  flex: 0;
}
@media screen and (max-width: 768px) {
  .content {
    display: block;
  }
  .content__col--rtd {
    padding: 0;
    margin: 0 0 30px;
  }
}