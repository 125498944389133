html {
  font: 16px Arial, Sans-Serif;
}

p.normal {
  margin-bottom: 10px;
}

.wh-rtd-editor .embeddedobject {
  padding: 30px 15px;
}

.wh-rtd-editor .embeddedobject__type {
  position: absolute;
  bottom: 10px;
  right: 6px;
  font-size: 140%;
  font-style: italic;
}
