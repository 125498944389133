html, body, form {
  padding: 0;
  margin: 0;
}

img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

datalist, template {
  display: none;
}

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

button {
  border: 0;
  border-radius: 0;
  background: none;
  cursor: pointer;
}

.wh-dialog {
  background: #fff;
  color: #000;
  padding: 10px;
}
.wh-dialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wh-dialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.wh-dialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.wh-dialog__modalbg {
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999999;
}
.wh-dialog .dompack-messagebox__buttongroup {
  margin-top: 35px;
  text-align: right;
}

#page-header-wrap .container .titlecol {
  margin-bottom: 0 !important;
}

#copyright {
  text-transform: uppercase;
}

@media screen and (max-width: 1024px) {
  html.intrim .logolink img {
    max-height: 20px;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 1px) {
  html.cvon #header-outer {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}
@media only screen and (max-width: 1000px) {
  html.cvon header#top .logolink img {
    height: 45px;
  }
  html.cvon header#top .logolink--first {
    margin-right: 10px;
  }
}
@media only screen and (max-width: 690px) {
  html.cvon .container, html.cvon div.slider-nav {
    max-width: 90vw !important;
  }
}